import { RouterState } from '@dayinsure/shared';
import { AuthProviderProps } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import Referrer from '../../types/referrer';
import { QuoteJourneyFormData } from '../../types';

export const getAcrValues = (referrer: Referrer, securityId?: string) =>
  `tenant:${referrer?.identityProviderName} referrer:${referrer?.name} brand:${
    referrer?.theme
  } ${securityId ? `securityId:${securityId}` : ''}`;

export const useAuthConfig = (referrer: Referrer): AuthProviderProps => {
  const navigate = useNavigate();
  return {
    authority: process.env.REACT_APP_AUTH_AUTHORITY || '',
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
    redirect_uri: `${window.origin}/login/redirect`,
    acr_values: getAcrValues(referrer),
    response_type: 'code',
    scope: process.env.REACT_APP_AUTH_SCOPE,
    extraTokenParams: {
      acr_values: getAcrValues(referrer),
    },
    automaticSilentRenew: true,
    onSigninCallback: (user): void => {
      const state = user?.state as RouterState<QuoteJourneyFormData>;
      const isReferrerDayinsure = state.referrerName?.toLowerCase() === 'dayinsure';

      if (state.quoteIdForReAccess) {
        // The user has just reauthenticated via the re-access enquiry, take them back to retail
        // in order to determine where to go next
        navigate(`/policies/${state.quoteIdForReAccess}`, { replace: true });
      } else {
        // A regular login. Go to either the returnUrl or the dashboard
        navigate(
          {
            pathname: state.returnPath || '/dashboard',
            search: isReferrerDayinsure
              ? `${state.completeQueryParamsFromJourney}`
              : `referrer=${state.referrerName?.toLowerCase()}&${
                  state.completeQueryParamsFromJourney
                }`,
          },
          {
            replace: true,
            state: {
              returnFromSignInRedirect: state.returnPath !== undefined,
              quoteJourneyData: state.quoteJourneyData,
            },
          }
        );
      }
    },
  };
};
