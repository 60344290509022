export const AgreementInfo = () => {
  const documentPrefix = process.env.REACT_APP_DOCUMENT_PREFIX || '';
  return (
    <div className="mt-12 font-lato text-sm text-center text-main-content-1">
      By continuing, I confirm I have read and agree with the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="font-semibold underline text-link"
        href={`${documentPrefix}resources/DocumentStore/Annual/Everydayinsure/PrivacyPolicy/EDI%20Privacy%20Policy%20v1.0.pdf`}
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        className="font-semibold underline text-link"
        href={`${documentPrefix}resources/DocumentStore/Annual/Everydayinsure/TOBA/EDI%20TOBA%20v1.0.pdf`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Business
      </a>
      .
    </div>
  );
};
